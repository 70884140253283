import {
  Button,
  Card,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { login } from '../actions/authActions';
import { useGlobal } from '../context/global';
import { isAdmin } from '../helpers/functions';
import restClient from '../providers/restClient';
import Charts from './charts';

export default () => {
  const notify = useNotify();
  const {
    databaseGlobal,
    setDatabaseGlobal,
    locationGlobal,
    setLocationGlobal,
  } = useGlobal();

  const [locations, setLocations] = useState([]);
  const [databasesBack, setDatabasesBack] = useState([]);
  const [database, setDatabase] = useState('');
  const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleConfirmAuth = (e) => {
    e.preventDefault();

    login({
      username: localStorage.getItem('username'),
      password: confirmPassword,
    })
      .then((res) => {
        if (res) {
          notify('Database Changed Successfully');
          localStorage.setItem('db', database);
          setDatabaseGlobal(database);
          setOpenConfirmPassword(false);
          setConfirmPassword('');
          setDatabase('');
        } else {
          notify('Incorrect username or password', 'error');
        }
      })
      .catch(() => {
        notify('Incorrect username or password', 'error');
      });
  };
  const returnSectionDatabase = () => (
    <div className="database">
      <h2 style={{ margin: '0' }}>Current Database: {databaseGlobal}</h2>

      <FormControl fullWidth>
        <InputLabel>Select Database</InputLabel>
        <Select
          value={database || databaseGlobal}
          fullWidth
          onChange={(e) => setDatabase(e.target.value)}
        >
          {databasesBack.map((db) => (
            <MenuItem key={db.id} value={db.year}>
              {db.year}
            </MenuItem>
          ))}
        </Select>

        {database !== '' && (
          <Button
            variant="contained"
            onClick={() => setOpenConfirmPassword(true)}
            style={{ marginTop: '1rem' }}
          >
            Confirm Database
          </Button>
        )}

        <Modal
          open={openConfirmPassword}
          onClose={() => setOpenConfirmPassword(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card style={{ padding: '1rem' }}>
            <h3>Confirm Authentication for Database: {database}</h3>

            <FormControl fullWidth>
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                variant="contained"
                onClick={handleConfirmAuth}
                color="success"
              >
                Confirm
              </Button>
            </FormControl>
          </Card>
        </Modal>
      </FormControl>
    </div>
  );

  const fetchLocations = () =>
    restClient.getList('location-list-only', {
      pagination: { page: 1, perPage: -1 },
      sort: { field: 'id', order: 'ASC' },
    });
  const fetchDatabases = () => restClient.getYears();

  useLayoutEffect(() => {
    fetchDatabases()
      .then(({ data }) => {
        const databases = data.map((database) => ({
          id: database.id,
          year: database.year,
        }));
        setDatabasesBack(databases);
      })
      .catch((err) => {
        console.log('Error fetching databases', err);
        setDatabasesBack([]);
      });

    if (isMinorTo2024()) {
      return;
    }
    fetchLocations()
      .then(({ data }) => {
        const locations = data.map((location) => ({
          id: location.id,
          name: location.name,
        }));
        setLocations(locations);
      })
      .catch((err) => {
        console.log('Error fetching locations', err);
        setLocations([]);
      });
  }, [database]);

  const isMinorTo2024 = () => {
    if (databaseGlobal < 2024 && databaseGlobal !== null) {
      return true;
    } else {
      return false;
    }
  };
  // console.log(isMinorTo2024());
  return (
    <Card className="card-wrapper">
      {isAdmin() ? (
        <div>
          <section className="dashboard-title">
            {returnSectionDatabase()}

            {!isMinorTo2024() ? (
              <div>
                <h2 style={{ margin: '0' }}>
                  Current Location: {locationGlobal}
                </h2>

                <FormControl fullWidth>
                  <InputLabel>Select Location</InputLabel>
                  <Select
                    value={locationGlobal}
                    onChange={(e) => {
                      setLocationGlobal(e.target.value);
                      localStorage.setItem('location', e.target.value);
                    }}
                  >
                    {locations?.map((location) => (
                      <MenuItem value={location.name} key={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : null}
          </section>

          <Divider style={{ margin: '2rem 0 1rem' }} />

          <div>
            <Charts />
          </div>
        </div>
      ) : (
        <h1
          style={{ color: '#00bcd4', textAlign: 'center', fontSize: '1.7rem' }}
        >
          Hello {localStorage.getItem('username')}, You have only{' '}
          <b>Add & View</b> rights in full system
        </h1>
      )}
    </Card>
  );
};
